<template>
  <tbody class='border-t'>
    <tr class='hover:bg-gray-50 text-sm'>
      <td class='py-2 lg:py-4 px-2 uppercase font-semibold'>
        <div class='whitespace-nowrap max-w-4xl truncate'>{{sessionWatchData.title}}</div>
      </td>
      <td class='py-2 lg:py-4 px-2 border-r text-right'>{{watchedFullString}}</td>
      <td class='py-2 lg:py-4 px-2 text-right'>{{scoreContributingTimeString(sessionWatchData.totalPlayedTime)}}</td>
    </tr>
  </tbody>
</template>

<script>
import DateTimeStringHelpers   from '@/utils/date-time-string-helpers'

export default {
  name: 'MyPageWatchedVideosCmccme',
  props: {
    sessionWatchData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dateForStuff () {
      return DateTimeStringHelpers.toDateWithDay(this.sessionWatchData.scheduledStartTime)
    },
    watchedFullString () {
      switch(this.sessionWatchData.watchedStatus) {
        case 'done':
          return '시청완료'
        case 'in_progress':
          return '시청중'
        default: 
          return ''
      }
    },
  },
  methods: {
    videoWatchedTimeString (seconds) {
      return DateTimeStringHelpers.secondToHourAndMinute(seconds)
    },
    scoreContributingTimeString (seconds) {
      return (this.sessionWatchData.watchedStatus === 'done') ? DateTimeStringHelpers.secondToHourAndMinute(seconds) : '-'
    },
  },
}
</script>

