<template>
  <div>
    <div class='text-2xl lg:text-3xl font-semibold uppercase w-full lg:w-1/4'>
      평점 확인
    </div>
    <div v-if='topHtmlContent' v-html='topHtmlContent' class='mt-4'></div>
    <div class='flex flex-col lg:flex-row gap-y-8 lg:gap-x-8 mt-4'>
      <div class='w-full lg:w-1/4 flex flex-col-reverse lg:flex-col gap-y-4'>
        <div class='w-full text-white flex flex-row justify-between items-start px-8 py-4 rounded-md'
          :style='`background-color: ${eventMainThemeColor};`'>
          <div class='text-lg uppercase font-normal mb-2'>총 이수 평점</div>
          <div class='text-3xl font-medium'>{{totalScore}}점</div>
        </div>
        <div class='w-full text-white flex flex-row justify-between items-start px-8 py-4 rounded-md'
          :style='`background-color: ${eventMainThemeColor};`'>
          <div class='text-lg uppercase font-normal mb-2'>총 시청 시간</div>
          <div class='text-3xl font-medium'>{{scoreableTimeTotalString}}</div>
        </div>
        <div class='lg:mt-8 mb-2' v-html='myPageScoreHelperText'></div>
      </div>
      <div class='w-full lg:w-3/4'>
        <table v-if='hasWatchedVideosData'
          class='table-fixed w-full text-sm lg:text-base'>
          <thead>
            <tr class='uppercase text-gray-600'>
              <th class='py-2 lg:py-4 px-2 text-left whitespace-nowrap align-top w-full'>강의</th>
              <th class='py-2 lg:py-4 px-2 border-r text-right w-16 lg:w-32 whitespace-nowrap align-top'>시청 여부</th>
              <th class='py-2 lg:py-4 px-2 text-right w-24 lg:w-32 whitespace-nowrap align-top'>평점 이수 시간</th>
            </tr>
          </thead>
          <my-page-watched-videos 
            v-for='(session, index) in myPageDataWatchedVideos'
            :key='`data-for-day-${session.title}-${index}`'
            :session-watch-data='session' />
            <tfoot>
              <tr class='font-semibold border-t'>
                <td class='py-2 lg:py-3 px-2 text-left whitespace-nowrap align-top uppercase w-full' colspan='2'>총 시청시간</td>
                <td class='py-2 lg:py-3 px-2 text-right whitespace-nowrap align-top w-24 lg:w-32'>{{scoreableTimeTotalString}}</td>
              </tr>
            </tfoot>
        </table>
        <div v-else
          class='my-2 py-16 bg-gray-100 rounded-md text-gray-600 text-center'>
          {{ emptyWatchedVideosText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MyPageWatchedVideos     from '@/components/my-page/MyPageWatchedVideosCmccme.vue'
import ScoreCalculationHelpers from '@/utils/score-calculation-helpers'
import DateTimeStringHelpers   from '@/utils/date-time-string-helpers'

export default {
  name: 'MyPageScoreCmccme',
  components: {
    MyPageWatchedVideos,
  },
  computed: {
    ...mapGetters('myPages', [
      'myPageDataWatchedVideos',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'eventMainThemeColor',
      'showingEventMyPageConfigurations',
    ]),
    myPageScoreHelperText () {
      return (this.showingEventMyPageConfigurations && this.showingEventMyPageConfigurations.myPageScoreConfigs) 
           ? this.showingEventMyPageConfigurations.myPageScoreConfigs.myPageScoreHelperText 
           : ''
    },
    emptyWatchedVideosText () {
      return (this.showingEventMyPageConfigurations && this.showingEventMyPageConfigurations.myPageScoreConfigs) 
           ? this.showingEventMyPageConfigurations.myPageScoreConfigs.emptyWatchedVideosText 
           : 'No videos have been watched yet.'
    },
    topHtmlContent () {
      return (this.showingEventMyPageConfigurations && this.showingEventMyPageConfigurations.myPageScoreConfigs) 
           ? this.showingEventMyPageConfigurations.myPageScoreConfigs.topHtmlContent 
           : ''
    },
    hasWatchedVideosData () {
      return this.myPageDataWatchedVideos.length > 0
    },
    totalTimeString () {
      return DateTimeStringHelpers.secondToHourAndMinute(this.myPageDataWatchedVideos.map(session => session.totalPlayedTime).reduce((a, b) => a + b, 0))
    },
    scoreableWatchedTimeTotalSeconds () {
      return this.myPageDataWatchedVideos.map(session => {
        return (session.watchedStatus.toLowerCase() === 'done') ? session.totalPlayedTime : 0
      }).reduce((a, b) => a + b, 0)
    },
    scoreableWatchedTimeTotalMinutes () {
      return Math.floor(this.scoreableWatchedTimeTotalSeconds / 60)
    },
    scoreableTimeTotalString () {
      return DateTimeStringHelpers.secondToHourAndMinute(this.scoreableWatchedTimeTotalSeconds)
    },
    totalScore () {
      return ScoreCalculationHelpers.calcedScore(40, '', this.scoreableWatchedTimeTotalMinutes)
    },
  },
}
</script>
